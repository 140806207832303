import clamp from 'lodash/clamp'
import {
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'

export type TabsConfig<ValueType> =
  | BaseTab<ValueType>[]
  | (() => BaseTab<ValueType>[])

export type BaseTab<ValueType> = {
  value: ValueType
  tab?: ReactNode
  element?: ReactElement
  show?: boolean
}

export interface UseTabsRouteState<ValueType> {
  tabIndex: number
  tabs: BaseTab<ValueType>[]
  getTab: (index: number) => BaseTab<ValueType>
}

const getConfig = <ValueType>(
  config: TabsConfig<ValueType>,
): BaseTab<ValueType>[] => (typeof config === 'function' ? config() : config)

export function useTabsRouteState<ValueType = string>(
  config: TabsConfig<ValueType>,
  value?: ValueType | null,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  deps?: any[],
): UseTabsRouteState<ValueType> {
  const tabsRef = useRef(getConfig(config))
  const tabs = tabsRef.current

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    tabsRef.current = getConfig(config)
  }, deps)

  const tabIndex = useMemo(() => {
    return clamp(
      tabs.findIndex((o) => o.value === value),
      0,
      tabs.length - 1,
    )
  }, [tabs, value])

  const getTab = useCallback(
    (index: number) => {
      return tabs[index]
    },
    [tabs],
  )

  return {
    tabIndex,
    getTab,
    tabs,
  }
}
